import { useEffect, useMemo, useState } from 'react';
import { OnboardingState } from '@onboarding/services';
import { IOnboardingTask } from '@onboarding/services';

interface FilterChecklistReturn {
  enabledChecklistItems: IOnboardingTask[];
  hasIncompleteTask: boolean;
  progress: number;
  // shouldAnimate: boolean;
}

// interface StoredState {
//   creatorHasCommunity: boolean;
//   creatorHasChallenge: boolean;
//   creatorHasContents: boolean;
//   tasksLength: number;
//   lastUpdated: number;
// }

// const STORAGE_BASE_KEY = 'onboarding_state';

// const getStorageKey = (creatorId: string) => `${STORAGE_BASE_KEY}_${creatorId}`;

// const getCreatorStoredState = (creatorId: string): StoredState | null => {
//   try {
//     const storedStateStr = localStorage.getItem(getStorageKey(creatorId));
//     return storedStateStr ? JSON.parse(storedStateStr) : null;
//   } catch (error) {
//     console.error('Error reading creator state from storage:', error);
//     return null;
//   }
// };

// const setCreatorStoredState = (creatorId: string, state: StoredState): void => {
//   try {
//     localStorage.setItem(getStorageKey(creatorId), JSON.stringify(state));
//   } catch (error) {
//     console.error('Error storing creator state:', error);
//   }
// };

const useFilterChecklist = (
  state: OnboardingState,
  userType?: 'fan' | 'creator',
): FilterChecklistReturn => {
  const [hasIncompleteTask, setHasIncompleteTask] = useState<boolean>(false);
  // const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const enabledChecklistItems = useMemo(() => {
    if (userType === 'fan') {
      const enabledByConfig = state?.progress?.tasklist?.filter((item) => item?.isEnabled);
      return enabledByConfig?.filter((item) => {
        if (item?.type === 'community') return state?.creatorHasCommunity;
        if (item?.type === 'challenges') return state?.creatorHasChallenge;
        if (item?.type === 'exclusive_content') return state?.creatorHasContents;
        return true;
      });
    }
    if (userType === 'creator') {
      return state?.progress?.tasklist;
    }
    return [];
  }, [
    state?.progress?.tasklist,
    state?.creatorHasCommunity,
    state?.creatorHasContents,
    state?.creatorHasChallenge,
    userType,
  ]);

  const progress = useMemo(() => {
    return Math.floor(
      (enabledChecklistItems?.filter((item) => item?.completed).length /
        (enabledChecklistItems?.length || 0)) *
        100,
    );
  }, [enabledChecklistItems]);

  useEffect(() => {
    const incompleteTaskFound = enabledChecklistItems?.some((item) => !item?.completed);
    setHasIncompleteTask(incompleteTaskFound);
  }, [state?.progress?.tasklist, enabledChecklistItems]);

  // Effect to check for resource changes using creator-specific localStorage
  // useEffect(() => {
  //   if (!state?.creator_id || !state) return;

  //   const currentState: StoredState = {
  //     creatorHasCommunity: state.creatorHasCommunity || false,
  //     creatorHasChallenge: state.creatorHasChallenge || false,
  //     creatorHasContents: state.creatorHasContents || false,
  //     tasksLength: enabledChecklistItems?.length || 0,
  //     lastUpdated: Date.now(),
  //   };

  //   // Get previous state for this specific creator
  //   const storedState = getCreatorStoredState(state.creator_id);

  //   if (storedState) {
  //     // Check for changes specific to this creator
  //     const communityBecameAvailable =
  //       !storedState.creatorHasCommunity && state.creatorHasCommunity;
  //     const challengeBecameAvailable =
  //       !storedState.creatorHasChallenge && state.creatorHasChallenge;
  //     const contentsBecameAvailable = !storedState.creatorHasContents && state.creatorHasContents;
  //     const tasksLengthIncreased =
  //       enabledChecklistItems?.length > storedState.tasksLength && storedState.tasksLength !== 0;

  //     // If any resource became available for this creator, trigger animation
  //     if (
  //       communityBecameAvailable ||
  //       challengeBecameAvailable ||
  //       contentsBecameAvailable ||
  //       tasksLengthIncreased
  //     ) {
  //       setShouldAnimate(true);
  //       setTimeout(() => {
  //         setShouldAnimate(false);
  //       }, 4000);
  //     }
  //   } else {
  //     // First time visiting this creator - animate if any resources are available
  //     const hasAnyResource =
  //       state.creatorHasCommunity || state.creatorHasChallenge || state.creatorHasContents;

  //     if (hasAnyResource) {
  //       setShouldAnimate(true);
  //       setTimeout(() => {
  //         setShouldAnimate(false);
  //       }, 4000);
  //     }
  //   }

  //   // Store current state for this creator
  //   setCreatorStoredState(state.creator_id, currentState);
  // }, [
  //   state?.creatorHasCommunity,
  //   state?.creatorHasChallenge,
  //   state?.creatorHasContents,
  //   enabledChecklistItems?.length,
  //   state?.creator_id,
  //   state,
  // ]);

  return {
    enabledChecklistItems,
    hasIncompleteTask,
    progress,
  };
};

export default useFilterChecklist;
