import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ILeaderboardPayload,
  LeaderboardResponse,
  LeaderboardRawResponse,
  IFan,
  IBadgeLeaderboardPayload,
  ICustomLeaderboardResponse,
  ICustomLeaderboardParams,
  IFanLeaderboardParams,
  ILeaderboardFanRawResponse,
} from '@ui/components';
import { QUERIES } from '@constants/query.constants';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IS_DEVELOPMENT } from '@constants/api.constants';
import { DATA_API_URL, API_URL } from '@constants/api.constants';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { cookieService } from '@lib/cookie.service';

const baseUrl = IS_DEVELOPMENT ? DATA_API_URL : API_URL;

export const dataQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const { accessToken, refreshToken } = cookieService.getAuthCookies();
    if (accessToken && refreshToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Refresh-token', refreshToken);
    }
    return headers;
  },
});

export const fansAdapter = createEntityAdapter({
  selectId: (item: IFan) => item.id,
});

export const fansSelector = fansAdapter.getSelectors();

export const leaderboardApi = createApi({
  reducerPath: 'leaderboard',
  baseQuery: dataQuery,
  tagTypes: [QUERIES.globalLeaderboard, QUERIES.leaderboards, QUERIES.leaderboardFans],
  endpoints: (builder) => ({
    getGlobalLeaderboard: builder.query<LeaderboardResponse, ILeaderboardPayload>({
      query: ({
        creatorId,
        initialDate,
        finalDate,
        weighted,
        page,
        pageSize,
        fanId,
        scoreRanking = 'badges',
      }) => {
        return {
          url: `/public/v1/top_fans_by_creator/${creatorId}`,
          params: {
            initial_date: initialDate,
            final_date: finalDate,
            top_n: 0,
            weighted: weighted,
            page: page,
            current_fan: fanId,
            items_per_page: pageSize,
            score_ranking: scoreRanking,
          },
        };
      },
      transformResponse({ data, total_pages, current_fan }: LeaderboardRawResponse) {
        const fans = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          data
            ? data.map((fan) => {
                return {
                  name: fan.username,
                  id: fan.userId,
                  score: fan.score,
                  engagement_score: fan.engagement_score,
                  overall_score: fan.overall_score,
                  rank: fan.rank,
                  avatar: fan.avatarUrl,
                  is_subscribed_to_creator: fan.is_subscribed_to_creator,
                };
              })
            : [],
        );
        return {
          currentFan: current_fan,
          fans: fans,
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSamePage = currentArg.page === previousArg?.page;

        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}`;
      },
      merge(currentState, incomingState) {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
      providesTags: [QUERIES.globalLeaderboard],
    }),
    getBadgeLeaderboard: builder.query<LeaderboardResponse, IBadgeLeaderboardPayload>({
      query: ({ badgeId, initialDate, finalDate, weighted, page, pageSize, fanId }) => {
        return {
          url: `/public/v1/top_fans_by_badge/${badgeId}?initial_date=${initialDate}&final_date=${finalDate}&top_n=${0}&weighted=${weighted}&page=${page}&current_fan=${fanId}&items_per_page=${pageSize}`,
        };
      },
      transformResponse({ data, total_pages, current_fan }: LeaderboardRawResponse) {
        const fans = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          data
            ? data.map((fan) => {
                return {
                  name: fan.username,
                  id: fan.userId,
                  score: fan.score,
                  rank: fan.rank,
                  avatar: fan.avatarUrl,
                  is_subscribed_to_creator: fan.is_subscribed_to_creator,
                };
              })
            : [],
        );

        return {
          currentFan: current_fan,
          fans: fans,
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameBadge = currentArg.badgeId === previousArg?.badgeId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameBadge || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.badgeId}`;
      },
      merge(currentState, incomingState) {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
    }),
    getCustomLeaderboards: builder.query<ICustomLeaderboardResponse, ICustomLeaderboardParams>({
      query: ({ initial_date = '', final_date = '', page = 1, pageSize = 10, creator_id }) => {
        return {
          url: `/public/v1/list_leaderboards/${creator_id}`,
          params: {
            initial_date,
            final_date,
            creator_id,
            page,
            items_per_page: pageSize,
          },
        };
      },
      transformResponse: ({ leaderboards, total_pages }: ICustomLeaderboardResponse) => {
        return {
          leaderboards,
          total_pages,
        };
      },
      providesTags: [QUERIES.leaderboards],
    }),
    getLeaderboardFans: builder.query<LeaderboardResponse, IFanLeaderboardParams>({
      query: ({ page = 1, pageSize = 10, leaderboardId, top_n, current_fan }) => {
        return {
          url: `/public/v1/display_leaderboard_fans/${leaderboardId}`,
          params: {
            page,
            leaderboard_id: leaderboardId,
            items_per_page: pageSize,
            top_n,
            current_fan,
          },
        };
      },
      transformResponse({
        fans,
        total_pages,
        total_fans,
        current_fan,
      }: ILeaderboardFanRawResponse): LeaderboardResponse {
        const fanEntities = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          fans.map((fan) => ({
            name: fan.username,
            score: fan.score,
            id: fan.reference_id,
            rank: fan.rank,
            avatar: fan.avatar_url,
            rankOutOf: total_fans,
            is_subscribed_to_creator: fan.is_subscribed_to_creator,
          })),
        );

        return {
          currentFan: current_fan,
          fans: { ...fanEntities },
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameLeaderboard = currentArg.leaderboardId === previousArg?.leaderboardId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameLeaderboard || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.leaderboardId}`;
      },
      merge(currentState, incomingState) {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
      providesTags: [QUERIES.leaderboardFans],
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetCustomLeaderboardsQuery,
  useGetLeaderboardFansQuery,
  useGetGlobalLeaderboardQuery,
  useGetBadgeLeaderboardQuery,
} = leaderboardApi;
