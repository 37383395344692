import { useState, useEffect } from 'react';
import { BackArrow } from '@ui/components/atoms/icon/icon-assets';
import { useMobile } from '@ui/hooks';
import './onboarding.css';
import { TaskList } from './task-list';
import { InstructionPanel } from './instruction-panel';
import { IOnboardingTask } from '@onboarding/services';
import { OnboardingState } from '../services/typings';
import useFilterChecklist from '../_hooks/useFllterChecklist';
import Congrats from './Congrats';

interface Props {
  state: OnboardingState;
  closeModal: () => void;
  userType: 'fan' | 'creator';
  onTabChange: ((tab: string) => void | undefined) | undefined;
  replacePlaceholders:
    | ((route: string, creatorId: string, communitySlug?: string) => string)
    | undefined;
}

export const OnboardingContent = ({
  state,
  closeModal,
  userType,
  onTabChange,
  replacePlaceholders,
}: Props) => {
  const [selectedTask, setSelectedTask] = useState<IOnboardingTask | null>(null);
  const [animationDirection, setAnimationDirection] = useState<'up' | 'down' | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const isMobile = useMobile();

  const { enabledChecklistItems } = useFilterChecklist(state, userType);

  useEffect(() => {
    if (!isMobile) {
      const firstTaskNotDone = enabledChecklistItems?.find((item) => !item?.completed);
      setSelectedTask(firstTaskNotDone || null);
    } else {
      setSelectedTask(null);
    }
  }, [isMobile]);

  const handleTaskSelect = (task: IOnboardingTask) => {
    if (isAnimating) return;

    const currentIndex = enabledChecklistItems.findIndex((item) => item?._id === selectedTask?._id);
    const newIndex = enabledChecklistItems.findIndex((item) => item?._id === task?._id);

    // Determine animation direction
    if (currentIndex === -1 || newIndex === -1) {
      setAnimationDirection('up');
    } else {
      setAnimationDirection(currentIndex < newIndex ? 'up' : 'down');
    }

    setIsAnimating(true);
    setSelectedTask(task);
  };

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300); // Match this with CSS animation duration
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  useEffect(() => {
    if (state?.progress?.progress?.has_new_task === true) {
      state.patchChecklistTrigger({
        creator_id: state?.creator_id,
        value: false,
        type: 'has_new_task',
        user_type: userType,
        skip: !userType,
      });
    }
  }, [state?.progress?.progress?.has_new_task]);

  const getAnimationClass = () => {
    if (!isAnimating) return '';
    return animationDirection === 'up' ? 'slide-up' : 'slide-down';
  };

  if (isMobile) {
    return (
      <div className="h-full">
        {selectedTask ? (
          <div className="flex flex-col h-full p-2">
            <button
              onClick={() => setSelectedTask(null)}
              className="flex items-center text-sm font-medium mb-4 space-x-2">
              <BackArrow className="h-5 w-5 mb-1" />
              <span className="text-sm font-semibold">Back to list</span>
            </button>
            <InstructionPanel
              task={selectedTask}
              getAnimationClass={getAnimationClass}
              creator_id={state?.creator_id}
              communitySlug={state?.communitySlug}
              closeModal={closeModal}
              onTabChange={onTabChange}
              replacePlaceholders={replacePlaceholders}
            />
          </div>
        ) : (
          <div className="">
            <TaskList
              checklistItems={enabledChecklistItems}
              selectedTask={selectedTask}
              handleTaskSelect={handleTaskSelect}
              userName={state?.userName}
              userType={userType}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex h-full w-full">
      <div className="w-1/3 max-w-1/3 border-r">
        <TaskList
          checklistItems={enabledChecklistItems}
          selectedTask={selectedTask}
          handleTaskSelect={handleTaskSelect}
          userName={state?.userName}
          userType={userType}
        />
      </div>
      <div className="w-2/3 max-w-2/3 h-full flex">
        {selectedTask ? (
          <InstructionPanel
            task={selectedTask}
            getAnimationClass={getAnimationClass}
            creator_id={state?.creator_id}
            communitySlug={state?.communitySlug}
            closeModal={closeModal}
            onTabChange={onTabChange}
            replacePlaceholders={replacePlaceholders}
          />
        ) : (
          <div className="flex items-center justify-center h-full w-full text-gray-500 text-center">
            <Congrats
              userType={userType}
              onClose={closeModal}
              onHideModalCta={state?.onHideModalCta}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingContent;
