import { createApi } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { baseQuery } from '../config/base-query';
import {
  IBadgeCheckoutResponse,
  IClaimBadgeData,
  ICreatorPublicResponse,
  IClaimBadgeCreatorData,
} from 'app/(badge)/claim.typings';
import { DATE_FORMATS } from '@ui/constants';
import { cookieService } from '@lib/cookie.service';

export const claimApi = createApi({
  reducerPath: 'claim-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getClaimBadge: builder.query<IClaimBadgeData, string>({
      query(badgeId) {
        const userId = cookieService.getUserId();
        if (!badgeId) {
          return {
            url: '',
          };
        }
        return `/badges/checkout/${badgeId}?id=${badgeId}&userId=${userId}`;
      },
      transformResponse(response: IBadgeCheckoutResponse | null) {
        if (!response) {
          return;
        }
        return {
          image: response?.external_url,
          name: response?.name,
          type: response?.type,
          platform: response?.media_source,
          badgeNumber: response?.serial_number,
          badgeSerial: response?.serial_number,
          amount: response?.quantity,
          transferDate: dayjs().format(DATE_FORMATS.full),
          isAvailable: response.availability !== 0,
          isClaimCode: response.claim_codes_active,
          isCollaboration: response.collaboration_enabled,
          collaboratorId: response.collaborator,
          badgeCreatorId: response.author,
          color: response.custom_badge_color,
          publicLink: response.public_link,
          caption: response.caption,
          perks: response.perquisites,
          allowedDomain: response.allowedDomain,
          message: response?.message,
          // owners: response.owner.map((o) => o.id),
          isExpired: response?.expired,
          sponsors: response?.sponsors,
          hasInstance: response.hasInstance,
        };
      },
    }),
    getClaimBadgeCreator: builder.query<IClaimBadgeCreatorData, string>({
      query(creatorId) {
        return `users-public-info/${creatorId}`;
      },
      transformResponse(response: ICreatorPublicResponse) {
        return {
          avatar: response?.avatarURL || '',
          name: response?.displayName || '',
        };
      },
    }),
  }),
});

export const { useGetClaimBadgeQuery, useGetClaimBadgeCreatorQuery } = claimApi;
