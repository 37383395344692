import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { COOKIES_MAP } from '@constants/api.constants';
import CryptoJS from 'crypto-js';
const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_AUTO_COOKIES_ENCRYPTION_KEY;

export interface IAuthOptions {
  accessToken: string;
  refreshToken: string;
  userId: string;
  isCreatorFanAccount?: boolean;
}

export const cookieService = {
  setAuthCookies({ accessToken, refreshToken, userId, isCreatorFanAccount }: IAuthOptions) {
    const maxAge = 60 * 60 * 24 * 365 * 5; //
    const expires = new Date(Date.now() + 60 * 60 * 24 * 365 * 5 * 1000);

    const options = {
      maxAge,
      expires,
    };

    setCookie(COOKIES_MAP.accessToken, accessToken, options);
    setCookie(COOKIES_MAP.refreshToken, refreshToken, options);
    setCookie(COOKIES_MAP.userId, userId, options);
    setCookie(COOKIES_MAP.isCreatorFanAccount, isCreatorFanAccount, options);
  },
  clearAuthCookies() {
    deleteCookie(COOKIES_MAP.accessToken);
    deleteCookie(COOKIES_MAP.refreshToken);
    deleteCookie(COOKIES_MAP.userId);
    deleteCookie(COOKIES_MAP.isCreatorFanAccount);
  },
  getAuthCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
      userId: getCookie(COOKIES_MAP.userId) || null,
      isCreatorFanAccount: getCookie(COOKIES_MAP.isCreatorFanAccount) || null,
    };
  },
  getAuthHeader() {
    const accessToken = getCookie(COOKIES_MAP.accessToken);
    const refreshToken = getCookie(COOKIES_MAP.refreshToken);
    return {
      Authorization: 'Bearer ' + accessToken,
      'Refresh-token': refreshToken ? refreshToken.toString() : '',
    };
  },
  getRequestCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
    };
  },
  getUserId() {
    const userId = getCookie(COOKIES_MAP.userId);
    return userId ? userId.toString() : '';
  },
  decryptAuthData(encryptedData: string) {
    try {
      if (!ENCRYPTION_KEY) {
        throw new Error('Encryption key not found');
      }

      const decodedData = decodeURIComponent(encryptedData);
      const decrypted = CryptoJS.AES.decrypt(decodedData, ENCRYPTION_KEY);
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      const decryptedData = JSON.parse(decryptedString);

      const MAX_AGE = 5 * 60 * 1000;
      if (Date.now() - decryptedData.timestamp > MAX_AGE) {
        throw new Error('Encrypted data has expired');
      }

      return {
        accessToken: decryptedData.accessToken,
        refreshToken: decryptedData.refreshToken,
        userId: decryptedData.userId,
        isCreatorFanAccount: decryptedData.isCreatorFanAccount,
      };
    } catch (error) {
      console.error('Decryption error:', error);
      throw new Error('Failed to decrypt auth data');
    }
  },
};
