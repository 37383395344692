import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  IOnboardingChecklist,
  IOnboardingProgress,
  IOnboardingProgressResponse,
  IOnboardingTask,
} from './typings';
import { QUERIES } from './constants';

export const onboardingAdapter = createEntityAdapter({
  selectId: (progress: IOnboardingProgress) => progress.creator_id,
});

export const onboardingSelector = onboardingAdapter.getSelectors();

export const onboardingApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'onboarding-api',
    baseQuery,
    tagTypes: [QUERIES.onboarding],
    endpoints: (builder) => ({
      getOnboardingProgress: builder.query<
        IOnboardingProgressResponse,
        { creator_id: string; user_type?: string }
      >({
        query: ({ creator_id, user_type = 'fan' }) => ({
          url: `/onboarding/progress/${creator_id}?user_type=${user_type}`,
        }),
        providesTags: [QUERIES.onboarding],
      }),
      getOnboardingChecklist: builder.query<IOnboardingChecklist, void>({
        query: () => ({ url: '/onboarding/checklist' }),
        providesTags: [QUERIES.onboarding],
      }),
      patchOnboardingTask: builder.mutation<
        IOnboardingTask,
        { task_id: string; completed: boolean; creator_id: string; user_type?: string }
      >({
        query: ({ task_id, completed, creator_id, user_type = 'fan' }) => ({
          url: `/onboarding/task/${task_id}`,
          method: 'PATCH',
          body: { completed, creator_id, task_id, user_type: user_type },
        }),
        invalidatesTags: [QUERIES.onboarding],
      }),
      patchOnboardingTrigger: builder.mutation<
        IOnboardingTask,
        {
          creator_id: string;
          value: boolean;
          user_type: 'fan' | 'creator';
          type: 'trigger' | 'has_new_task';
        }
      >({
        query: ({ creator_id, value, user_type, type = 'trigger' }) => ({
          url: `/onboarding/checklist/trigger`,
          method: 'PATCH',
          body: { creator_id, value, user_type, type },
        }),
        invalidatesTags: [QUERIES.onboarding],
      }),
    }),
  });

  return api;
};
