import { CheckIcon } from '@ui/components/atoms/icon/icon-assets/check';
import { Title } from '@ui/components';
import { Cube } from '@ui/components/atoms/icon/icon-assets/cube';
import './onboarding.css';
import { IOnboardingTask } from '@onboarding/services';
import { motion } from 'framer-motion';
import { OnboardIcon } from '@ui/components/atoms/icon/icon-assets/onboard';

interface TaskListProps {
  checklistItems: IOnboardingTask[];
  selectedTask: IOnboardingTask | null;
  userName: string;
  userType: 'fan' | 'creator';
  handleTaskSelect: (task: IOnboardingTask) => void;
}

export const TaskList = ({
  checklistItems,
  selectedTask,
  handleTaskSelect,
  userName,
  userType,
}: TaskListProps) => {
  return (
    <div className="flex flex-col space-y-2 overflow-y-scroll scrollbar-none max-h-[75vh]">
      <div className="border-b w-full px-6 py-6 font-semibold flex items-center">
        <OnboardIcon className="w-12 h-12 md:w-16 md:h-16 mr-4" /> ONBOARDING
      </div>
      <div className="w-full p-6">
        <Title type="s" className="font-semibold text-md leading-8">
          Welcome aboard {userName || ''}!
        </Title>
        <div className="text-xs font-normal leading-[20px] text-[#32363E]">
          We’ve curated this onboarding checklist to help you quickly dive into KOR. Let’s get
          started!
        </div>
        <div className="flex items-center justify-between mt-4 space-x-2">
          <div className="flex items-center font-semibold text-sm">
            <span>{checklistItems?.filter((item) => item?.completed)?.length}</span> <span>/</span>{' '}
            <span>{checklistItems?.length}</span>
          </div>
          <div className="w-full h-[10px] bg-gray-200 rounded-sm flex justify-start items-center">
            <motion.span
              initial={{ width: '0%' }}
              animate={{
                width: `${
                  (checklistItems?.filter((item) => item?.completed)?.length /
                    checklistItems?.length || 0) * 100
                }%`,
              }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
              className="h-[10px] bg-primary rounded-sm"></motion.span>
          </div>
        </div>
      </div>
      <div>
        {checklistItems
          ?.filter((item) => item?.isEnabled)
          .map((item, idx) => (
            <motion.div
              initial={{ opacity: 1, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.2 * idx,
                ease: 'easeInOut',
              }}
              key={item._id}
              onClick={() => handleTaskSelect(item)}
              className={`flex items-center justify-between py-5 border-t cursor-pointer px-6 
              hover:bg-gray-50/50 transition-colors duration-200
              ${selectedTask?._id === item?._id ? 'border-l-4 border-l-primary' : ''}`}>
              <div className="flex items-center space-x-2">
                <Cube />
                <span className="text-sm font-medium">{item?.title}</span>
              </div>
              {item?.completed && (
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#00D149]">
                  <CheckIcon stroke="white" />
                </div>
              )}
            </motion.div>
          ))}
      </div>
    </div>
  );
};
