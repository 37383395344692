'use client';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { INoteBook } from '@cms/services';

type NotebookHero = {
  singleNotebook: INoteBook;
};

const NotebookHero = ({ singleNotebook }: NotebookHero) => {
  const shouldShowPublishDate = useMemo(() => {
    if (!singleNotebook?.miscellaneous) return true;
    return singleNotebook?.miscellaneous?.showPublishDate ?? true;
  }, [singleNotebook?.miscellaneous]);
  return (
    <div>
      {singleNotebook?.banner_url && (
        <img
          src={singleNotebook?.banner_url}
          alt="Notebook banner"
          className="rounded-xl h-[230px] w-full mb-[30px] object-cover object-center"
        />
      )}

      <div className="mb-[30px] space-y-2">
        {shouldShowPublishDate && <p>{dayjs(singleNotebook?.created_at).format('DD MMM')}</p>}

        {singleNotebook?.name && (
          <h1 className="text-2xl md:text-4xl font-bold">{singleNotebook?.name}</h1>
        )}

        {singleNotebook?.description && <p>{singleNotebook?.description}</p>}
      </div>
    </div>
  );
};

export default NotebookHero;
