import { createApi } from '@reduxjs/toolkit/query/react';
import { authQuery } from '../config/base-query';
import { cookieService } from '@lib/cookie.service';
import { QUERIES } from '@constants/query.constants';
import { setUser } from '../features/user';
import { IUserReponse } from 'typings/api.typigns';
import { IUser, IImageUploadResponse } from 'typings';
import { IUserBodyPayload } from 'app/(app)/settings/user.typings';
import {
  IGetFanProfileResponse,
  ICreatorPublicProfile,
  ICreatorPublicProfileResponse,
} from 'app/(app)/fan-profile/fan-profile.typings';

export const userApi = createApi({
  reducerPath: 'user-api',
  baseQuery: authQuery,
  tagTypes: [QUERIES.user],
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query() {
        const userId = cookieService.getUserId();

        if (!userId) {
          return {
            url: '',
          };
        }
        return {
          url: `/users/${userId}`,
          method: 'GET',
        };
      },
      transformResponse(user: IUserReponse) {
        return {
          email: user.email,
          id: user._id,
          avatar: user.avatarURL,
          phone: user.phoneNumber || '+01 23456789',
          name: user.firstName || user?.companyName || 'Your Name',
          username: user?.displayName || user.userName || 'yourusername',
          isOnboarded: user.onboarded,
          displayName: user?.displayName, // Need to be extremely careful with this field
        };
      },
      async onQueryStarted(args: void, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          return;
        }
      },
      providesTags: [QUERIES.user],
    }),
    updatePassword: builder.mutation<void, Record<string, string>>({
      query({ oldPassword, newPassword }) {
        const userId = cookieService.getUserId();
        return {
          url: `/users/password-reset`,
          method: 'PUT',
          body: {
            id: userId,
            old_password: oldPassword,
            new_password: newPassword,
          },
        };
      },
    }),
    updateUser: builder.mutation<void, Partial<IUserBodyPayload>>({
      query(payload) {
        const userId = cookieService.getUserId();
        return {
          url: `/update-user-settings/${userId}`,
          method: 'PATCH',
          body: payload,
        };
      },
      invalidatesTags: (response, error, request) => {
        return error ? [] : [QUERIES.user];
      },
    }),
    uploadImage: builder.mutation<string, { height: number; width: number; image: File }>({
      query: ({ height, width, image }) => {
        const form = new FormData();
        form.append('file', image);
        return {
          url: `/image-upload?height=${height}&width=${width}?double_image=false`,
          method: 'POST',
          body: form,
          formData: true,
        };
      },
      transformResponse(data: IImageUploadResponse) {
        return data.location;
      },
    }),
    getFanProfile: builder.query<IGetFanProfileResponse, { fanId: string; creatorId: string }>({
      query({ fanId, creatorId }) {
        return {
          url: `/get-fan-profile/${fanId}?author=${creatorId}`,
        };
      },
    }),
    deleteAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [QUERIES.user],
    }),
    getPublicProfile: builder.query<ICreatorPublicProfile, string>({
      query: (id) => {
        return {
          url: `/users-public-info/${id}`,
        };
      },
      transformResponse: (data: ICreatorPublicProfileResponse) => {
        return {
          avatar: data.avatarURL,
          name: data.companyName,
          displayName: data.displayName,
          banner: data.imageURL,
          id: data._id,
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
  useUploadImageMutation,
  useGetFanProfileQuery,
  useGetPublicProfileQuery,
  useDeleteAccountMutation,
} = userApi;
