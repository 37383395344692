import { Button } from '@ui/components';
// import animage from '../../../ui/src/assets/images/fanLogogif.gif';
import { IOnboardingTask } from '@onboarding/services';
import { useRouter } from 'next/navigation';
import { useMemo, useState } from 'react';

// Updated interface to include new props that replace the imports
interface InstructionPanelProps {
  task: IOnboardingTask;
  getAnimationClass: () => '' | 'slide-up' | 'slide-down';
  creator_id: string;
  communitySlug?: string | undefined;
  closeModal: () => void;
  onTabChange: ((tab: string) => void | undefined) | undefined;
  replacePlaceholders:
    | ((route: string, creatorId: string, communitySlug?: string) => string)
    | undefined;
}

export const InstructionPanel = ({
  task,
  getAnimationClass,
  communitySlug,
  creator_id,
  closeModal,
  // Destructure new props
  onTabChange,
  replacePlaceholders,
}: InstructionPanelProps) => {
  const router = useRouter();
  const uniqueKey = useMemo(() => task?._id || Date.now(), [task]);

  const [mediaURI, setMediaURI] = useState<string | null>(null);

  const handleRouteNavigation = () => {
    if (onTabChange && replacePlaceholders) {
      const finalRoute = replacePlaceholders(task?.route!, creator_id, communitySlug);
      if (finalRoute) {
        const tabFragment = finalRoute.split('#tab=')[1];
        const tabMapping = {
          leaderboard: 'Leaderboards',
          badges: 'All Badges',
        };

        if (tabFragment && (tabFragment === 'leaderboard' || tabFragment === 'badges')) {
          onTabChange(tabMapping[tabFragment]);
          closeModal?.();
        }
        router.push(finalRoute);
      }
    } else {
      router.push(task?.route || '');
    }
    return;
  };

  return (
    <div
      className={`flex flex-col items-start w-full space-y-4 overflow-y-scroll scrollbar-none pb-8 ${getAnimationClass()}`}
      key={uniqueKey}>
      <div className="w-full mb-2 bg-gray-100 min-h-[300px] overflow-hidden">
        {task?.mediaURI?.type === 'image' ? (
          <div className="w-full overflow-hidden h-full">
            <img
              src={task?.mediaURI?.mobileURI || 'animage.src'}
              alt={task.title}
              className="w-full h-full max-h-[64vh] object-contain md:hidden"
              key={uniqueKey}
              loading="eager"
            />
            <img
              src={task?.mediaURI?.desktopURI || 'animage.src'}
              alt={task.title}
              className="w-full h-full max-h-[64vh] object-contain hidden md:block"
              key={uniqueKey}
              loading="eager"
            />
          </div>
        ) : (
          <div className="w-full overflow-hidden">
            <video
              src={task?.mediaURI?.mobileURI || 'animage.src'}
              controls
              className="w-full h-full lg:hidden"
              key={uniqueKey}
            />
            <video
              src={task?.mediaURI?.mobileURI || 'animage.src'}
              controls
              className="w-full h-full hidden lg:block"
              key={uniqueKey}
            />
          </div>
        )}
      </div>

      <div className="md:p-4 space-y-4 md:space-y-3">
        <div className="text-lg font-semibold">{task?.title}</div>
        <div className="text-sm text-gray-600 !mt-0">{task?.description}</div>
        <Button
          type="primary"
          className={`w-[40px] rounded-sm h-[40px] text-sm ${task?.route == null && 'hidden'}`}
          onClick={() => {
            handleRouteNavigation();
            closeModal();
          }}>
          Try it
        </Button>
      </div>
    </div>
  );
};
